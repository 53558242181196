<div *ngIf="formItemNmLoaded">
    <div class="relativeh-[calc(100vh_-_var(--vex-toolbar-height)_-_var(--vex-navigation-height)_-_var(--vex-footer-height))] overflow-hidden"
        @scaleFadeIn>
        <c-carousel [dark]="true" [wrap]="true" (itemChange)="onItemChange($event)">
            <c-carousel-indicators></c-carousel-indicators>
            <c-carousel-inner>
                <c-carousel-item *ngFor="let slide of slides; let i = index">
                    <!-- Image -->
                    <img alt=" {{slide.title}}"
                        class="w-full h-[calc(100vh_-_var(--vex-toolbar-height)_-_var(--vex-navigation-height)_-_var(--vex-footer-height))] md:object-cover xs:object-cover"
                        loading="lazy" [src]="slide.src" />
                    <!-- Caption -->
                    <c-carousel-caption class="absolute bottom-1/2  text-white bg-blue-600/20 rounded-md"
                        *ngIf="slide.showCaption" @scaleFadeIn>
                        <p class="text-lg sm:text-2xl md:text-3xl lg:text-4xl font-bold leading-relaxed"
                            [innerHTML]="slide.subtitle"></p>
                    </c-carousel-caption>
                </c-carousel-item>
            </c-carousel-inner>
            <!-- Navigation Controls -->
            <c-carousel-control caption="Previous" direction="prev"></c-carousel-control>
            <c-carousel-control caption="Next" direction="next"></c-carousel-control>
        </c-carousel>
    </div>


    <div class="h-full flex flex-col top-card-container py-20">
        <div class="overflow-y-auto flex-auto">
            <div class="text-center text-white mb-10">
                <h1 class="text-4xl font-extrabold mb-4 tracking-wide transition-all duration-500 ease-in-out"
                    [innerHTML]="listFormItemNm[7]">
                </h1>
                <p class="text-lg md:text-xl opacity-90" [innerHTML]="listFormItemNm[8]"></p>
            </div>
            <div class="p-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 container pb-20">
                <div class="top-card overflow-hidden cursor-pointer" matRipple>
                    <h2 class="top-card-header" [innerHTML]="listFormItemNm[9]"></h2>
                    <div class="top-card-img">
                        <img class="object-cover" src="./assets/img/topcard/topcard_01.jpg" alt="Card Image" />
                    </div>
                    <div class="top-card-desc">
                        <p [innerHTML]="listFormItemNm[10]"></p>
                    </div>
                </div>

                <div class="top-card overflow-hidden cursor-pointer" matRipple>
                    <h2 class="top-card-header">{{listFormItemNm[11]}}</h2>
                    <div class="top-card-img">
                        <img class="object-cover" src="./assets/img/topcard/topcard_02.jpg" alt="Card Image" />
                    </div>
                    <div class="top-card-desc">
                        <p [innerHTML]="listFormItemNm[12]"></p>
                    </div>
                </div>

                <div class="top-card overflow-hidden cursor-pointer" matRipple>
                    <h2 class="top-card-header" [innerHTML]="listFormItemNm[13]"></h2>
                    <div class="top-card-img">
                        <img class="object-cover" src="./assets/img/topcard/topcard_03.jpg" alt="Card Image" />
                    </div>
                    <div class="top-card-desc">
                        <p [innerHTML]="listFormItemNm[14]"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="px-6 lg:px-8 py-20 h-full bg-foreground">
        <div class="container space-y-20">
            <!-- NEWS -->
            <div class="grid grid-cols-1 md:grid-cols-1 card gap-6">
                <div class="ring-2 ring-primary-500 rounded-2xl relative">
                    <div class="text-center">
                        <div [innerHTML]="listFormItemNm[15]"
                            class="absolute inline-block rounded-full bg-primary-600 text-on-primary-600 font-semibold xs:text-sm sm:text-xl md:text-2xl px-5 py-3 -translate-y-[50%] -translate-x-[50%]">

                        </div>
                    </div>

                    <div class="mx-8 py-8 space-y-4 pt-10">
                        <div>
                            <span class="text-md d-block font-extrabold" [innerHTML]="listFormItemNm[16]"></span>
                            <span class="text-md d-block font-extrabold" [innerHTML]="listFormItemNm[17]"></span>
                        </div>
                    </div>

                    <div class="container mx-auto md:pl-20 md:pr-20">
                        <!-- Timeline -->
                        <div class="relative border-l-2">
                            <div class="mb-8 flex items-center cursor-pointer" *ngFor="let post of listPosts"
                                (click)="postClick(post)">
                                <div
                                    class="flex items-center  justify-center w-6 h-6 bg-blue-500 text-white text-xs font-bold rounded-full -ml-3 z-10">
                                    {{post.INDEX}}
                                </div>
                                <div class="w-full ml-8">
                                    <div class="border-b border-gray-300 mb-4"></div>
                                    <p class="text-blue-500 font-semibold">{{post.CREATED_AT}}</p>
                                    <p class="text-gray-700" [innerHTML]="post.TITLE"></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-center space-x-4 pb-10">
                        <div class="">
                            <button class="rounded-full py-2 px-6 title" color="primary" mat-raised-button
                                (click)="linkToNews()" type="button">
                                <mat-icon class="ltr:-ml-2 rtl:-mr-2 ltr:mr-2 rtl:ml-2 !icon-lg"
                                    svgIcon="mat:menu"></mat-icon>
                                <span [innerHTML]="listFormItemNm[18]"></span>
                            </button>
                        </div>
                    </div>
                    <div class="flex justify-center mb-8">
                        <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)"
                            [length]="length" [pageSize]="pageSize" [disabled]="disabled"
                            [showFirstLastButtons]="showFirstLastButtons"
                            [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                            [pageIndex]="pageIndex" aria-label="Select page">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="w-full bg-blue-500 py-20">
        <!-- Title và Subtitle -->
        <div class="text-center text-white mb-10 px-6">
            <h1 [innerHTML]="listFormItemNm[19]"
                class="text-4xl font-extrabold mb-4 tracking-wide transition-all duration-500 ease-in-out hover:text-gray-300">

            </h1>
            <p class="text-lg md:text-xl opacity-90" [innerHTML]="listFormItemNm[20]"></p>
        </div>

        <!-- Cards Section -->
        <div class="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 px-4 pb-20">
            <!-- Card 1 -->
            <div class="card-container bg-white rounded-lg shadow-lg flex flex-col h-full p-6">
                <h2 class="text-3xl font-bold mb-8 text-center pt-5 pb-2" [innerHTML]="listFormItemNm[21]"></h2>
                <div class="flex flex-col md:flex-row items-stretch h-full">
                    <div class="md:w-1/2 flex flex-col justify-center">
                        <p class="text-lg font-semibold text-center md:text-left" [innerHTML]="listFormItemNm[22]">

                        </p>
                    </div>
                    <div class="md:w-1/2 relative overflow-hidden">
                        <img src="./assets/img/topgallery/topgallery_01.png" alt="入国記念"
                            class="card-image w-full h-full object-cover">
                    </div>
                </div>
            </div>

            <!-- Card 2 -->
            <div class="card-container bg-white rounded-lg shadow-lg flex flex-col h-full p-6">
                <h2 class="text-3xl font-bold mb-8 text-center pt-5 pb-2" [innerHTML]="listFormItemNm[23]"></h2>
                <div class="flex flex-col md:flex-row items-stretch h-full">
                    <div class="md:w-1/2 flex flex-col justify-center">
                        <p class="text-lg font-semibold text-center md:text-left" [innerHTML]="listFormItemNm[24]">

                        </p>
                    </div>
                    <div class="md:w-1/2 relative overflow-hidden">
                        <img src="./assets/img/topgallery/topgallery_02.png" alt="現地選考会"
                            class="card-image w-full h-full object-cover">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>